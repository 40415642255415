input[type="button"] {
  background-color: #052e66;
  cursor: pointer;
}

input[type="button"]:active {
  background-color: #10052e66;
}

/* input[type="button"]:hover { */
/* background-color: #10052e66; */
/* border: 2px; */
/* border-color: yellow; */
/* } */

.text {
  color: #777;
  font-weight: 24px;
}
.text:hover {
  color: #f60;
}
